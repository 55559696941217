import Particle from "./Particle"

const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const clear = () => {
    ctx.fillStyle = "black"
    ctx.fillRect(0, 0, canvas.width, canvas.height)
}

const canvas = document.querySelector("#canvas")
const ctx = canvas.getContext("2d")
ctx.canvas.width = window.innerWidth
ctx.canvas.height = window.innerHeight

ctx.fillStyle = "black"
ctx.fillRect(0, 0, canvas.width, canvas.height)

const howManyDots = 20
let shape = random(0, 2)
let particles = []

const generateShapes = () => {
    for (let i = 0; i < howManyDots; i++) {
        const particle = new Particle(
            {
                x: window.innerWidth / 2 + random(0, 150),
                y: window.innerHeight / 2 + random(0, 150)
            },
            random(5, 30)
        )
        particles.push(particle)
    }
    shape = random(0, 2)
}

generateShapes()

document.getElementById("restart").addEventListener("click", () => {
    clear()
    generateShapes()
})

let time = 0
let lastTime = 0
let deltaTime = 0
let now

let divideTime = random(100, 1000)

setInterval(() => {
    divideTime = random(300, 1000)
}, 2000)

function animate() {
    requestAnimationFrame(animate)
    now = Date.now()
    deltaTime = now - lastTime
    time += deltaTime

    for (let i = 0; i < particles.length; i++) {
        let radiusPos = Math.sin(time / divideTime + 1) + 10
        let x = (Math.cos(time / divideTime) * radiusPos) / 2
        let y = (Math.sin(time / divideTime) * radiusPos) / 2

        if (i % 4 === 0) {
            radiusPos = -Math.sin(time / divideTime + 1) * 5 + random(10, 20)
            x = (-Math.cos(time / divideTime) * radiusPos) / 3
            y = (Math.sin(time / divideTime) * radiusPos) / 3
        }

        if (i % 5 === 0) {
            radiusPos = -Math.sin(time / divideTime + 1) * 5 + 10
            x = (Math.cos(time / divideTime) * radiusPos) / 3
            y = (-Math.sin(time / divideTime) * radiusPos) / 3
        }

        if (i % 7 === 0) {
            radiusPos = Math.sin(time / divideTime + 1) * 5 + 10
            x = (Math.cos(time / divideTime) * radiusPos) / 3
            y = (-Math.sin(time / divideTime) * radiusPos) / 3
        }

        if (particles[i].radius > 0.11) {
            particles[i].radius -= 0.1
            particles[i].draw(ctx, shape, 0)
            particles[i].update(x, y, particles[i].radius)
        }
    }

    lastTime = now
}

animate()

window.addEventListener("resize", () => {
    ctx.canvas.width = window.innerWidth
    ctx.canvas.height = window.innerHeight
})
