export default class Particle {
    constructor(pos, radius) {
        this.pos = pos
        this.radius = radius
    }

    update(x, y) {
        this.pos.x += x
        this.pos.y += y

        if (this.pos.x >= window.innerWidth || this.pos.x < 0) {
            this.pos.x = window.innerWidth / 2
            this.pos.y = window.innerHeight / 2
        }

        if (this.pos.y >= window.innerHeight || this.pos.y < 0) {
            this.pos.x = window.innerWidth / 2
            this.pos.y = window.innerHeight / 2
        }
    }

    draw(ctx, shape, offset) {
        if (shape === 0) {
            ctx.beginPath()
            ctx.arc(this.pos.x + offset, this.pos.y + offset, this.radius + offset / 5, 0, 2 * Math.PI)
            ctx.fillStyle = "white"
            ctx.strokeStyle = "black"
            ctx.lineWidth = 3
            ctx.stroke()
            ctx.fill()
            ctx.closePath()
        } else if (shape === 1) {
            ctx.beginPath()
            ctx.rect(this.pos.x, this.pos.y, this.radius + offset / 5, this.radius + offset / 5)
            ctx.fillStyle = "white"
            ctx.strokeStyle = "black"
            ctx.lineWidth = 3
            ctx.stroke()
            ctx.fill()
            ctx.closePath()
        } else {
            ctx.save()
            ctx.beginPath()
            ctx.translate(this.pos.x + offset, this.pos.y + offset)
            ctx.moveTo(-this.radius / 2, this.radius / 2)
            ctx.lineTo(0, -this.radius / 2)
            ctx.lineTo(this.radius / 2, this.radius / 2)
            ctx.lineTo(-this.radius / 2, this.radius / 2)
            ctx.fillStyle = "white"
            ctx.strokeStyle = "black"
            ctx.lineWidth = 3
            ctx.stroke()
            ctx.fill()
            ctx.closePath()
            ctx.restore()
        }
    }
}
